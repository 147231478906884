import angular from 'angular';
import 'angular-scroll';
import 'angular-sanitize';
import 'angular-animate';

require('./vendor/SweetAlert');
require('./vendor/sweetalert.min');
require('./vendor/airlst-sdk-1.1.1');

require('./airlst.js');

angular
  .module('app', [
    'oitozero.ngSweetAlert',
    'AirLST-SDK',
    'duScroll',
    'ngSanitize',
  ])
  .directive('ngEnter', [
    function () {
      return function (scope, element, attrs) {
        element.bind('keydown keypress', function (event) {
          if (event.which === 13) {
            scope.$apply(function () {
              scope.vm.loadRsvp();
            });

            event.preventDefault();
          }
        });
      };
    },
  ])

  .directive('ngSlideRight', [
    () => {
      return (scope, element) => {
        element.bind('keydown keypress', (event) => {
          if (event.which === 39) {
            scope.$apply(() => {
              scope.vm.slideRight();
            });
            event.preventDefault();
          }
        });
      };
    },
  ])

  .directive('ngSlideLeft', [
    () => {
      return (scope, element) => {
        element.bind('keydown keypress', (event) => {
          if (event.which === 37) {
            scope.$apply(() => {
              scope.vm.slideLeft();
            });
            event.preventDefault();
          }
        });
      };
    },
  ])
  .controller('AirLSTCtrl', [
    '$scope',
    '$location',
    'SweetAlert',
    '$document',
    'AirLSTSdkService',

    function ($scope, $location, SweetAlert, $document, AirLSTSdkService) {
      var vm = this;

      vm.isLightboxOpen = false;
      vm.currentLightboxOpen = '';

      vm.imageIndex = null;

      vm.hasError = false;
      vm.loading = true;
      vm.submitting = false;
      vm.hasCode = false;
      vm.menucheckbox = false;

      vm.tab = 'survey';
      // vm.tab = 'done';
      // vm.tab = 'gallery';

      vm.mobileNavIsOpen = false;
      // vm.currentView = 'survey';
      // vm.companion = false;
      // vm.guestAnrede = '';
      // vm.representative;
      // vm.emailRepeat = false;
      // vm.addressOpen = false;
      // vm.hotelLimitRiched = false;

      var survey = angular.element(document.getElementById('survey'));
      var anmeldung = angular.element(document.getElementById('anmeldung'));
      var gallery = angular.element(document.getElementById('gallery'));

      vm._init = function () {
        AirLSTSdkService.companyUid = 'U14AP97';
        AirLSTSdkService.guestlistUid = 'IZXLP9FFGT';
        AirLSTSdkService.apiUrl = 'https://v1.api.airlst.com/lp';

        vm._resetRsvpInformation();
        vm._retrieveEventInformation();
        vm.rsvpInformation = {
          rsvp: {},
          contact: {},
        };
      };

      vm.setTab = function (tabId) {
        vm.tab = tabId;
        if (vm.tab == 'generalForm') {
          $document.scrollToElement(anmeldung);
        }
        if (vm.tab == 'survey') {
          console.log(vm.rsvpInformation.rsvp.status);
          $document.scrollToElement(survey);
        } else {
        }
        $scope.$applyAsync();
      };

      vm.isSet = function (tabId) {
        return vm.tab === tabId;
        $scope.$applyAsync();
      };

      vm.setImageIndex = (index) => {
        vm.imageIndex = index;
        $scope.$applyAsync();
      };

      vm.slideRight = () => {
        vm.imageIndex++;
        if (vm.imageIndex >= vm.gallery.length) {
          vm.imageIndex = 0;
        }
      };

      vm.slideLeft = () => {
        vm.imageIndex--;
        if (vm.imageIndex < 0) {
          vm.imageIndex = vm.gallery.length - 1;
        }
      };

      vm.toggleLightbox = (lightboxToToggle) => {
        if (lightboxToToggle === '') {
          vm.isLightboxOpen = false;
        } else {
          vm.isLightboxOpen = true;
          vm.currentLightboxOpen = lightboxToToggle;
        }
        $scope.$applyAsync();
      };

      vm._retrieveEventInformation = () => {
        vm.eventInformation = null;

        AirLSTSdkService.retrieveGuestlistInformation().then(
          (eventInformation) => {
            vm.eventInformation = eventInformation;
            $scope.$applyAsync();
          },
          () => {
            vm.hasError = true;

            swal(
              'Fehler',
              'Die Informationen zum gewünschten Event konnten nicht geladen werden.',
              'error',
            );
          },
        );
      };

      vm._checkRsvpActionOnUrl = () => {
        const match = $location.absUrl().match(/action=([A-Za-z0-9]+)/);

        if (match && match.length >= 2 && match[1]) {
          return match[1];
        } else {
          return false;
        }
      };

      vm.openDataPrivacy = function () {
        vm.overlay = true;
        vm.dataPrivacy = true;
        window.scroll(0, 0);
      };

      vm.openImprint = function () {
        vm.overlay = true;
        vm.imprint = true;
        window.scroll(0, 0);
      };

      vm.closeOverlay = function () {
        vm.overlay = false;
        vm.dataPrivacy = false;
        vm.imprint = false;
      };

      vm.createRsvp = (form) => {
        if (!vm._validateRegistrationForm(form)) {
          return;
        }
        vm.submitting = true;

        if (!vm.rsvpInformation.hasOwnProperty('rsvp')) {
          vm.rsvpInformation.rsvp = {};
        }

        vm.rsvpInformation.rsvp.status = 'confirmed';

        AirLSTSdkService.submitOpenRequest(
          vm._prepareRsvpInformationForApi(),
        ).then(
          (rsvpInfo) => {
            vm.submitting = false;
            vm.setTab('done');
          },
          ({ identifier }) => {
            vm.hasError = true;
            vm.submitting = false;

            switch (identifier) {
              case 'validation':
                SweetAlert.swal(
                  'Daten fehlerhaft',
                  'Bitte überprüfen Sie Ihre Daten.',
                  'error',
                );
                break;
              default:
                SweetAlert.swal(
                  'Fehler',
                  'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.',
                  'error',
                );
                break;
            }
          },
        );
      };

      vm.goToView = function (viewToGoTo) {
        vm.currentView = viewToGoTo;
        $scope.$applyAsync();
      };

      vm.setAnswerType = function (newAnswerType) {
        vm.answerType = newAnswerType;
      };

      vm._checkRsvpCodeOnUrl = function () {
        var match = $location.absUrl().match(/rsvp_code=([A-Za-z0-9]+)/);

        if (match && match.length >= 2 && match[1]) {
          vm.rsvpCode = match[1];
          vm.loadRsvp();
        } else {
          vm.loading = false;
        }
      };

      vm._checkRsvpActionOnUrl = function () {
        var match = $location.absUrl().match(/action=([A-Za-z0-9]+)/);

        if (match && match.length >= 2 && match[1]) {
          return match[1];
        } else {
          return false;
        }
      };

      vm._validateRegistrationForm = function (form) {
        vm.formValidationReg = true;

        if (!form.$valid) {
          SweetAlert.swal(
            'Fehlende Daten',
            'Bitte füllen Sie alle Pflichtfelder aus',
            'warning',
          );
          return false;
        } else {
          vm.formValidationReg = false;

          return true;
        }
      };

      vm._retrieveEventInformation = function () {
        vm.eventInformation = null;

        AirLSTSdkService.retrieveGuestlistInformation().then(
          function (eventInformation) {
            vm.eventInformation = eventInformation;
            switch (vm._checkRsvpActionOnUrl()) {
              case 'cancel':
                vm.cancelRsvp();
                break;
              case 'umfrage':
                vm.setTab('survey');

                break;
              case 'gallery':
                vm.setTab('gallery');

                break;
              default:
                break;
            }
            $scope.$applyAsync();
          },
          function () {
            vm.hasError = true;

            SweetAlert.swal(
              'Fehler',
              'Die Informationen zum gewünschten Event konnten nicht geladen werden',
              'error',
            );
          },
        );
      };

      vm._prepareRsvpInformationForApi = function () {
        return vm.rsvpInformation;
      };

      vm._prepareRsvpInformationFromApi = function (inData) {
        return inData;
      };

      vm._resetRsvpInformation = function () {
        vm.rsvpInformation = null;
      };

      vm._init();
      vm._checkRsvpCodeOnUrl();
      vm._checkRsvpActionOnUrl();
    },
  ]);
